@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: Inter, sans-serif;
}

.red {
    background-color: red;
}

.stroke {
    stroke-linecap: round;
}

.dark .path-track {
    stroke-linecap: round;
    stroke-dasharray: 175;
    stroke-width: 1px;
    stroke-dashoffset: 130;
    stroke: #60a5fa;
    fill: none;
    animation: dash2 5s ease-in-out infinite;
}

.dark .path {
    stroke-dasharray: 175;
    stroke-width: 3px;
    stroke-dashoffset: 0;
    stroke: #60a5fa;
    fill: none;
    opacity: 0.5;
    animation: dash 3s ease-in-out infinite;
}

.dark ::-webkit-scrollbar {
    width: 10px;
}

.dark ::-webkit-scrollbar-track {
    background-color: #0f172a;
}

.dark ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #60a5fa95;
}

.path-track {
    stroke-linecap: round;
    stroke-dasharray: 175;
    stroke-width: 1px;
    stroke-dashoffset: 130;
    stroke: #2563eb;
    fill: none;
    animation: dash2 5s ease-in-out infinite;
}

.path {
    stroke-dasharray: 175;
    stroke-width: 3px;
    stroke-dashoffset: 0;
    stroke: #2563eb;
    fill: none;
    opacity: 0.5;
    animation: dash 3s ease-in-out infinite;
}

@keyframes dash2 {
    from {
        stroke-dashoffset: 130;
    }

    to {
        stroke-dashoffset: 480;
    }
}

@keyframes dash {
    from {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: 350;
    }
}

@layer base {
    @font-face {
        font-family: 'Avant Garde';
        font-weight: 500;
        src: url('../public/fonts/AvantGardeITCbyBT-Demi.otf') format('opentype');
    }
}

.slider {
    --color: red;
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 20px;
    /* Specified height */
    border-radius: 100px;
    outline: none;
    /* Remove outline */
    opacity: 0.7;
    /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: 0.2s;
    /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    @apply bg-variant-10;
    border: 4px solid var(--color);
    cursor: pointer;
    /* Cursor on hover */
}

.slider::-moz-range-thumb {
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background-color: var(--color);
    overflow: visible;
    cursor: pointer;
}

table {
    border-collapse: collapse;
}

thead {
    position: sticky;
    top: 0;
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;

        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;

        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;

        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;

        --border: 216 34% 17%;
        --input: 216 34% 17%;

        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;

        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;

        --destructive: 0 63% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 216 34% 17%;

        --radius: 0.5rem;
    }
}

.dark input {
    color-scheme: dark;
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
        font-feature-settings: 'rlig' 1, 'calt' 1;
    }
}
